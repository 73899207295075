import React, { useRef, useEffect, useState } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useWindowSize from "../hooks/useWindowSize";
// Components
import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/header";
import StickyHeader from "../components/stickyHeader";
import featureImage from "../images/feature_use_case.jpg";
import mobileFeatureImage from "../images/feature_use_case_mobile.jpg";
import useCaseMain from "../images/use_case_main.jpg";
import useCaseLeft from "../images/use_case_left.jpg";
import useCaseRight from "../images/use_case_right.jpg";

const UseCase = () => {
  const featureImgEl = useRef(null);
  const featureContent = useRef(null);
  const mainTitle = useRef(null);
  const mainImage = useRef(null);
  const leftImage = useRef(null);
  const rightImage = useRef(null);
  const introText = useRef(null);
  const [ featureImg, setFeatureImg ] = useState("");
  const { width } = useWindowSize();

  useEffect(() => {
    // Jumbotron animation
    gsap.from(featureImgEl.current, {
      duration: 0.8,
      autoAlpha: 0,
      y: 200,
      ease: "power3",
    });
    gsap.from(featureContent.current, {
      duration: 0.8,
      autoAlpha: 0,
      y: 300,
      ease: "power3",
      delay: 0.5
    });

    // Content animation
    gsap.registerPlugin(ScrollTrigger);
    gsap.from(mainTitle.current, {
      duration: 1,
      autoAlpha: 0,
      y: 150,
      ease: "power3",
      scrollTrigger: {
        trigger: mainTitle.current,
        start: "bottom bottom",
        toggleActions: "play none none reverse"
      }
    });
    gsap.from(mainImage.current, {
      duration: 1,
      autoAlpha: 0,
      y: 200,
      ease: "power3",
      delay: 0.75,
      scrollTrigger: {
        trigger: mainTitle.current,
        start: "bottom bottom",
        end: "bottom bottom+=200",
        toggleActions: "play none none reverse"
      }
    });

    const sideImageTrigger = {
      trigger: mainImage.current,
      start: "center+=100 center",
      toggleActions: "play none none reverse",
    }
    const sideImageTween = {
      duration: 1,
      autoAlpha: 0,
      y: width > 850 ? 150 : 100,
      ease: "power3",
      delay: 0.25,
      scrollTrigger: sideImageTrigger
    };
    gsap.from(leftImage.current, sideImageTween);
    gsap.from(rightImage.current, sideImageTween);

    gsap.from(introText.current, {
      duration: 0.75,
      autoAlpha: 0,
      y: width > 850 ? 100 : 50,
      ease: "power3",
      delay: 0.25,
      scrollTrigger: {
        trigger: rightImage.current,
        start: width > 850 ? "top bottom" : "top bottom",
        toggleActions: "play none none reverse",
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(width > 850) {
      setFeatureImg(featureImage);
    } else {
      setFeatureImg(mobileFeatureImage);
    }
  }, [width]);

  return (
    <Layout>
      <SEO title="Use Case" />
      <Header>
        <div className="jumbotron d-flex">
          <div className="container use-case__feature">
            <img className="use-case__feature--image" src={featureImg} alt="About Coolso" ref={featureImgEl} />
            <div className="use-case__feature--content" ref={featureContent}>
              <h2 className="use-case__feature--title">Together with our<br/>clients and partners,<br/>we shape the future.</h2>
            </div>
          </div>
        </div>
      </Header>
      <StickyHeader />
      <section className="content">
        <div className="content__section container use-case__content">
          <h2 className="use-case__content--title" ref={mainTitle}>Industrial{width > 850 ? " " : <br/>}AR Controller</h2>
          <div className="use-case__intro">
            <div className="use-case__intro--main-image" ref={mainImage}>
              <img src={useCaseMain} alt="Industrial AR Controller"/>
            </div>
            <div className="use-case__intro--side-image" ref={leftImage}>
              <img src={useCaseLeft} alt="The problem we are facing"/>
              <p className="paragraph--meta">Users need to take off gloves that impact productivity.</p>
            </div>
            <div className="use-case__intro--side-image" ref={rightImage}>
              <img src={useCaseRight} alt="Our solution"/>
              <p className="paragraph--meta">NO need to take off gloves when wear CoolSo’s gesture wristband to control anything.</p>
            </div>
          </div>
          <p className="paragraph use-case__intro--text" ref={introText}>The customer appreciates the gesture control via muscle activities detection, as their workers need to wear heavy protective gloves and it is hard for vision-based gesture solution to perform. Users need to take off gloves when wear CoolSo’s gesture wristband to control anything.</p>
        </div>
      </section>
    </Layout>
  )
}

export default UseCase
